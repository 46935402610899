import React from 'react';

const IconLogo = () => (
  <svg
    id="logo"
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="300.000000pt"
    height="300.000000pt"
    viewBox="0 0 300.000000 300.000000"
    preserveAspectRatio="xMidYMid meet">
    <metadata>Created by potrace 1.16, written by Peter Selinger 2001-2019</metadata>
    <g
      transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
      fill="#64ffda"
      stroke="purple">
      <path
        d="M1347 2969 c-186 -21 -388 -82 -527 -157 -47 -25 -86 -49 -88 -54 -2
-4 -10 -8 -18 -8 -8 0 -14 -3 -14 -8 0 -4 -13 -13 -28 -20 -81 -35 -346 -298
-393 -389 -7 -15 -17 -30 -20 -33 -24 -20 -134 -241 -144 -290 -2 -8 -4 -16
-5 -17 -2 -2 -4 -7 -5 -13 -1 -5 -3 -11 -5 -12 -1 -2 -3 -10 -6 -18 -2 -8 -10
-40 -19 -70 -62 -221 -62 -540 0 -760 9 -30 18 -62 20 -70 2 -8 4 -16 5 -17 2
-2 4 -7 5 -13 1 -5 4 -12 5 -15 1 -3 3 -8 4 -12 7 -43 124 -276 146 -293 4 -3
9 -12 12 -21 9 -23 126 -167 186 -227 67 -66 212 -182 229 -182 7 0 13 -3 13
-8 0 -4 9 -12 19 -17 11 -6 58 -31 104 -57 80 -44 222 -101 283 -112 16 -2 40
-8 54 -11 219 -54 517 -50 730 10 30 9 62 17 70 19 8 3 16 5 18 6 1 2 6 3 11
4 5 0 13 4 17 9 4 4 16 7 26 7 18 0 247 114 258 128 3 4 17 13 31 20 94 49
361 314 409 408 8 16 17 31 20 34 19 19 98 169 127 240 39 97 79 271 93 399
29 263 -24 570 -137 796 -24 50 -51 97 -59 106 -8 8 -14 21 -14 27 0 7 -4 12
-10 12 -5 0 -10 6 -10 13 0 15 -69 108 -146 194 -61 70 -222 208 -271 233 -15
8 -30 17 -33 21 -20 22 -229 126 -270 134 -8 2 -16 4 -17 5 -2 2 -7 4 -13 5
-5 1 -11 3 -12 5 -2 1 -10 3 -18 6 -245 68 -421 86 -613 63z m64 -595 c53 -27
59 -58 59 -319 l0 -235 83 0 c45 0 92 -3 105 -6 l23 -7 -4 239 c-2 132 -7 250
-11 265 -6 21 -3 27 19 37 39 18 85 14 130 -11 70 -39 75 -61 75 -311 l0 -219
237 7 c130 4 244 11 254 16 12 7 21 5 29 -5 19 -22 8 -120 -17 -153 l-20 -27
-242 -3 -241 -2 0 -424 c1 -476 -1 -466 69 -466 28 0 45 7 64 25 34 35 75 123
93 204 20 86 36 111 74 111 37 0 90 -61 90 -104 0 -33 -38 -143 -75 -217 -81
-159 -251 -239 -387 -180 -73 31 -113 89 -132 191 -2 14 -5 213 -5 443 l-1
417 -86 0 c-47 0 -94 5 -105 10 -19 11 -19 2 -20 -510 -1 -286 -2 -525 -3
-531 -2 -20 -40 -39 -78 -39 -35 0 -66 11 -108 39 -13 9 -16 112 -20 788 -5
771 -5 778 -25 776 -16 -2 -52 -9 -62 -12 -2 -1 -5 -1 -8 -1 -21 2 -134 -72
-170 -112 -94 -105 -127 -202 -124 -369 2 -110 25 -197 72 -268 24 -36 32 -41
60 -39 30 3 32 1 35 -31 8 -79 -60 -171 -124 -171 -31 1 -99 46 -118 79 -6 11
-28 49 -49 85 -69 119 -92 207 -92 351 0 112 10 174 43 267 23 65 81 164 124
214 71 81 247 186 334 200 22 3 59 10 84 14 76 13 139 11 171 -6z"
      />
    </g>
  </svg>
);

export default IconLogo;
